import React, { FC } from 'react';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';

import Header, { HeaderType } from '../Common/Header';

import { ServicesTileDataObject } from '../../../providers/TypeProvider';
import Paragraph, { ParagraphType, TextType } from '../Common/Paragraph';


interface ServicesSimpleTilesProps {
    data: ServicesTileDataObject[]
}
 
const ServicesSimpleTiles: FC<ServicesSimpleTilesProps> = (props: ServicesSimpleTilesProps) => (
    <>
        { 
            props.data.map((tile: ServicesTileDataObject, index: number) => (
                    <div className="col-24-xs col-8-lg">
                        <div className="services__simple-tile" key={ index }>
                            <GatsbyImage
                                className="services__simple-tile-icon"
                                image={ getImage(tile.icon) as IGatsbyImageData }
                                alt={ tile.alt }
                            />
                            <Header
                                type={ HeaderType.H4 }
                                text={ tile.headline }
                            />
                            <Paragraph
                                type={ ParagraphType.REGULAR }
                                textType={ TextType.STRING }
                                text={ tile.paragraph }
                            />
                        </div>
                    </div>
                )
            ) 
        }
    </>
);

 
export default ServicesSimpleTiles;
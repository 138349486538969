import React, { FC } from 'react';
import { motion } from 'framer-motion';

import Arrow from '../Elements/Common/Arrow';
import Header, { HeaderType } from '../Elements/Common/Header';
import Paragraph, { ParagraphType, TextType } from '../Elements/Common/Paragraph';
import Button, { ButtonType, LinkType } from '../Elements/Common/Button';

import { HeroDataType } from '../../providers/TypeProvider';
import { simpleFadeInAndTranslateVertical, simpleFadeInAndTranslateHorizontal } from '../../providers/AnimationProvider';
import { ButtonLinks } from '../../providers/LinkProvider';

import video from '../../videos/Hero2.webm';
import videoFallback from '../../videos/Hero2.mp4';

interface VideoShowcaseProps {
    data: HeroDataType
}
 
const VideoShowcase: FC<VideoShowcaseProps> = (props: VideoShowcaseProps) => {
    const buttonAnimation = simpleFadeInAndTranslateVertical(.65, 0, 100);

    return ( 
        <section className="video-showcase" id="video-showcase">
            <video className="video-showcase__video" autoPlay muted loop>
                    <source src={ video } type="video/webm" />
                    <source src={ videoFallback } type="video/mp4" />
                    Your browser does not support the video tag.
            </video>
            <div className="video-showcase__overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-24-xs">
                        <div className="video-showcase__content-container">
                            <Header
                                animate={ simpleFadeInAndTranslateHorizontal(.65, 0, -100) }
                                type={ HeaderType.H1 }
                                text={ props.data.headline1 }
                                text2={ props.data.headline2 }
                            />
                            <Paragraph
                                className="white"
                                animate={ simpleFadeInAndTranslateHorizontal(.65, .5, -100) }
                                type={ ParagraphType.REGULAR }
                                textType={ TextType.STRING }
                                text={ props.data.tagline } />
                            <motion.div className="video-showcase__btn-container"
                                initial={ buttonAnimation.initial }
                                animate={ buttonAnimation.animate }
                                transition={ buttonAnimation.transition}>
                                <Button
                                    type={ ButtonType.PRIMARY }
                                    linkType={ LinkType.INTERNAL }
                                    text={ props.data.primaryButton }
                                    link={ ButtonLinks.bookNow }
                                />
                                <Button
                                    type={ ButtonType.SECONDARY }
                                    linkType={ LinkType.INTERNAL }
                                    text={ props.data.secondaryButton }
                                    link={ ButtonLinks.arrow }
                                />
                            </motion.div>
                            <div className="video-showcase__arrow-container">
                                <Arrow />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default VideoShowcase;
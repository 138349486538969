import React from 'react';
import { graphql, PageProps } from 'gatsby';

import PageBundler from '../components/Sections/PageBundler';

import { 
    HeroDataType, 
    AboutDataType, 
    ServicesDataType, 
    PricingDataType,
    ContactDataType } from '../providers/TypeProvider';

const IndexPage = (props: PageProps) => {
    const query: any = props.data;
    const heroQuery = query.prismicHeroSection.data;
    const aboutQuery = query.prismicAboutSection.data;
    const servicesQuery = query.prismicServicesSection.data;
    const pricingQuery = query.prismicPricesSection.data;
    const contactQuery = query.prismicContactSection.data;

    const heroData: HeroDataType = {
        headline1: heroQuery.header_headline_1,
        headline2: heroQuery.header_headline_2,
        tagline: heroQuery.tagline,
        primaryButton: heroQuery.primary_button,
        secondaryButton: heroQuery.secondary_button
    }
    
    const aboutData: AboutDataType = {
        image: aboutQuery.image,
        alt: aboutQuery.image.alt,
        header: aboutQuery.headline,
        paragraphs: aboutQuery.paragraphs.map((i: any) => i.paragraph),
        header2: aboutQuery.header_2,
        checklistItems1: aboutQuery.checklist_items_1.map((i: any) => i.checklist_item),
        header3: aboutQuery.header_3,
        checklistItems2: aboutQuery.checklist_items_2.map((i: any) => i.checklist_item)
    }

    const servicesTileItems = servicesQuery.body
        .filter((i: any) => i?.hasOwnProperty('tileItems'))
        .map((i: any) => {
            return {
                icon: i.tileItems[0].icon,
                alt: i.tileItems[0].icon.alt,
                headline: i.tileItems[0].headline,
                paragraph: i.tileItems[0].paragraph
            } 
        }
    );

    const servicesListItems = servicesQuery.body
        .filter((i: any) => i?.hasOwnProperty('listItems'))
        .map((i: any) => {
            return {
                image: i.listItems[0].image,
                alt: i.listItems[0].image.alt,
                headline: i.listItems[0].headline,
                paragraph: i.listItems[0].paragraph
            }
        }
    );

    const servicesData: ServicesDataType = {
        header: servicesQuery.headline,
        tagline: servicesQuery.tagline,
        servicesTiles: servicesTileItems,
        servicesItems: servicesListItems
    }

    const priceCards = pricingQuery.body.map((i: any) => {
        return {
            tierName: i.items[0].tier_name,
            priceText: i.items[0].price_text,
            currencyText: i.items[0].currency_text,
            buttonText: i.items[0].button_text
        }
    })

    const pricingData: PricingDataType = {
        header: pricingQuery.headline,
        tagline: pricingQuery.tagline,
        priceCard1List: pricingQuery.price_card_1_list.map((i: any) => i.price_card_1_list_item),
        priceCard2List: pricingQuery.price_card_2_list.map((i: any) => i.price_card_2_list_item),
        priceCard3List: pricingQuery.price_card_3_list.map((i: any) => i.price_card_3_list_item),
        priceCards
    }

    const contactData: ContactDataType = {
        header: contactQuery.headline,
        tagline: contactQuery.tagline,
        buttonText: contactQuery.button_text,
        image: contactQuery.image,
        alt: contactQuery.image.alt
    }

  return (
    <PageBundler 
        heroData={ heroData }
        aboutData={ aboutData }
        servicesData={ servicesData }
        pricingData={ pricingData }
        contactData={ contactData }
    />
  )
}

export const dataQuery = graphql`
    query IndexQuery {
    prismicHeroSection {
        data {
            header_headline_1
            header_headline_2
            tagline
            primary_button
            secondary_button
        }
    }
    prismicAboutSection {
        data {
            headline
            image {
                alt
                gatsbyImageData(placeholder: BLURRED)
            }
            paragraphs {
                paragraph
            }
            header_2
            checklist_items_1
            {
                checklist_item
            }
            header_3
            checklist_items_2
            {
                checklist_item
            }
        }
    }
    prismicServicesSection {
        data {
            headline
            tagline
            body {
                ... on PrismicServicesSectionDataBodyServicesTile {
                    tileItems: items {
                        headline
                        icon {
                            alt
                            gatsbyImageData(placeholder: BLURRED)
                        }
                        paragraph
                    }
                }
                ... on PrismicServicesSectionDataBodyServicesItems {
                    listItems: items {
                        headline
                        paragraph
                        image {
                            alt
                            gatsbyImageData(placeholder: BLURRED)
                        }
                    }
                }
            }
        }
    }
    prismicPricesSection {
        data {
            headline
            tagline
            price_card_1_list {
                price_card_1_list_item
            }
            price_card_2_list {
                price_card_2_list_item
            }
            price_card_3_list {
                price_card_3_list_item
            }
            body {
                ... on PrismicPricesSectionDataBodyPriceCard {
                    items {
                        tier_name
                        price_text
                        currency_text
                        button_text
                    }
                }
            }
        }
    }
    prismicContactSection {
        data {
            headline
            tagline
            button_text
            image {
                alt
                gatsbyImageData(placeholder: BLURRED)
            }
        }
    }
}
`

export default IndexPage;

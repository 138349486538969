import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

import Button, { ButtonType, LinkType } from '../Elements/Common/Button';
import Header, { HeaderType } from '../Elements/Common/Header';
import Paragraph, { ParagraphType, TextType } from '../Elements/Common/Paragraph';
import InfoBlock from '../Elements/Common/InfoBlock';

import { simpleFadeInAndTranslateHorizontal, simpleFadeIn } from '../../providers/AnimationProvider';
import { schema } from '../../providers/SchemaProvider';
import { ContactDataType } from '../../providers/TypeProvider';

interface ContactUsProps {
    data: ContactDataType
}

const Contact: FC<ContactUsProps> = (props: ContactUsProps) => {
    const alt = "Image of Lotus Spa Office";

    const [formSubmitted, setFormSubmitted] = useState('');
    const [formStyle, setFormStyle] = useState('');

    const { register, handleSubmit, formState: { errors }, formState } = useForm({ resolver: yupResolver(schema) });

    const submitForm = async (data: any) => {
        try {
            let res = await fetch('https://blank/api/submit', { // TODO set link
              method: "POST",
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({ name: data.name, email: data.email }),
            });

            if (res.status === 200) {
                setFormSubmitted(() => 'Your message has been sent! We will get back to you shortly.');
                setFormStyle(() => 'green');
            } else {
                setFormSubmitted(() => 'Please fix any errors in the form.');
                setFormStyle(() => 'red');
            }
          } catch (err) {
            setFormSubmitted(() => 'Please fix any errors in the form.');
            setFormStyle(() => 'red');
          }
    };
        
    const textAnimation1 = simpleFadeInAndTranslateHorizontal(.65, 0, -100);
    const textAnimation2 = simpleFadeIn(.65, .5);

    return ( 
        <section className="contact-us" id="contact-us">
            <div className="container">
                <div className="row">
                    <div className="col-24-xs col-12-xl">
                        <motion.form 
                            className="contact-us__form" 
                            onSubmit={ handleSubmit(submitForm) } 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            transition={ textAnimation1.transition }
                            variants={ { 
                                hidden: textAnimation1.initial,
                                visible: textAnimation1.animate
                            }}>
                            <Header
                                className="capitalize"
                                type={ HeaderType.H3 }
                                text={ props.data.header }
                            />
                            <Paragraph
                                type={ ParagraphType.REGULAR }
                                textType={ TextType.STRING }
                                text={ props.data.tagline }
                            />
                            <div className="contact-us__form-input">
                                <input type="text" { ...register("name") } id="name" required />
                                <label htmlFor="name">Name*</label>
                            </div>
                            <div className="contact-us__form-input">
                                <input type="email" { ...register("email") } id="email" required />
                                <label htmlFor="email">Email*</label>
                            </div>
                            <Button
                                type={ ButtonType.TERTIARY }
                                linkType={ LinkType.CONTACT }
                                text={ props.data.buttonText }
                            />
                            <small style={{ color: formStyle }}>{ formSubmitted }</small>
                        </motion.form>
                    </div>
                    <div className="col-24-xs col-12-xl">
                        <div className="contact-us__info-container">
                            <GatsbyImage 
                                className="contact-us__info-img"
                                image={ getImage(props.data.image) as IGatsbyImageData }
                                alt={ props.data.alt }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default Contact;
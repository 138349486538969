import React, { FC } from 'react';
import { motion } from 'framer-motion';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';

import Header, { HeaderType } from '../Elements/Common/Header';
import Paragraph, { ParagraphType, TextType } from '../Elements/Common/Paragraph';

import { AboutDataType } from '../../providers/TypeProvider';
import { simpleFadeInAndTranslateHorizontal } from '../../providers/AnimationProvider';

interface AboutProps {
    data: AboutDataType
}

const About: FC<AboutProps> = (props: AboutProps) => { 
    const textAnimation = simpleFadeInAndTranslateHorizontal(.65, 0, 100);

    return (
        <section className="about" id="about-us">
            <div className="container">
                <div className="row">
                    <div className="col-24-xs col-8-lg">
                        <GatsbyImage 
                            className="about__img"
                            image={ getImage(props.data.image) as IGatsbyImageData }
                            alt={ props.data.alt }
                        />
                    </div>
                    <div className="col-4-lg"></div>
                    <div className="col-24-xs col-12-lg">
                        <motion.div className="about__text-container"
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            transition={ textAnimation.transition }
                            variants={ { 
                                hidden: textAnimation.initial,
                                visible: textAnimation.animate
                            }}>
                            <Header
                                type={ HeaderType.H2 }
                                text={ props.data.header }
                            />
                            <Paragraph
                                type={ ParagraphType.REGULAR }
                                textType={ TextType.ARRAY }
                                paragraphs={ props.data.paragraphs }
                                className="about__paragraph"
                            />
                        </motion.div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-24-xs col-12-lg">
                            <Header 
                                type={ HeaderType.H4 }
                                text={ props.data.header2 }
                            /> 
                            <Paragraph
                                type={ ParagraphType.REGULAR }
                                textType= { TextType.ARRAY }
                                paragraphs={ props.data.checklistItems1 }
                                className="about__list"
                            />
                    </div>
                    <div className="col-24-xs col-12-lg">
                            <Header 
                                type={ HeaderType.H4 }
                                text={ props.data.header3 }
                            /> 
                            <Paragraph
                                type={ ParagraphType.REGULAR }
                                textType= { TextType.ARRAY }
                                paragraphs={ props.data.checklistItems2 }
                                className="about__list"
                            />
                        </div>
                    </div>
                </div>
        </section>
    );
}
 
export default About;
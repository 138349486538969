import React, { FC } from 'react';

import Header, { HeaderType } from '../Common/Header';
import Paragraph, { ParagraphType, TextType } from '../Common/Paragraph';
import Button, { ButtonType, LinkType } from '../Common/Button';

import { PriceCardDataObject } from '../../../providers/TypeProvider';
import { ButtonLinks } from '../../../providers/LinkProvider';
;

interface PricingCardsProps {
    priceCard1List: string[],
    priceCard2List: string[],
    priceCard3List: string[],
    priceCards: PriceCardDataObject[]
}
 
const PricingCards: FC<PricingCardsProps> = (props: PricingCardsProps) => {
    const renderSwitch = (index: number) => {
        switch (index) {
            case 0:
                return props.priceCard1List;
            case 1:
                return props.priceCard2List;
            case 2:
                return props.priceCard3List;
            default:
                return [''];
        }
    }
    
    return (
        <>
            {
                props.priceCards.map((card: PriceCardDataObject, index: number) => (
                    <div className="col-24-xs col-8-lg">
                        <div className={ `pricing__card ${ index === 1 ? 'highlight' : '' }` } key={ index }>
                            <span className="pricing__tier">{ card.tierName }</span>
                            <span className="pricing__price">{ card.priceText }</span>
                            <span className="pricing__currency">{ card.currencyText }</span>
                            <Paragraph
                                type={ ParagraphType.REGULAR }
                                textType= { TextType.ARRAY }
                                paragraphs={ renderSwitch(index) }
                                className="pricing__list"
                            />
                            <Button 
                                type={ ButtonType.SECONDARY }
                                linkType={ LinkType.INTERNAL }
                                link={ ButtonLinks.bookNow }
                                text={ card.buttonText }
                            />
                        </div>
                    </div>
                ))
            }
        </>
    );
}
 
export default PricingCards;
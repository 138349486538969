import React, { FC } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

import ServicesSimpleItems from '../Elements/Services/ServicesSimpleItems';
import ServicesSimpleTiles from '../Elements/Services/ServicesSimpleTiles';
import Header, { HeaderType } from '../Elements/Common/Header';
import Paragraph, { ParagraphType, TextType } from '../Elements/Common/Paragraph';

import { simpleFadeInAndTranslateHorizontal } from '../../providers/AnimationProvider';
import { ServicesDataType } from '../../providers/TypeProvider';

interface ServicesProps {
    data: ServicesDataType
}

 
const Services : FC<ServicesProps> = (props: ServicesProps) => {
    const textAnimation = simpleFadeInAndTranslateHorizontal(.65, 0, -100)

    return (
        <section className="services" id="services">
            <div className="container">
                <div className="row">
                    <div className="col-24-xs col-6-lg">
                        <motion.div 
                            className="services__content-container" 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            transition={ textAnimation.transition }
                            variants={ { 
                                hidden: textAnimation.initial,
                                visible: textAnimation.animate
                            }}>
                            <Header
                                type={ HeaderType.H2 }
                                text={ props.data.header }
                            />
                            <Paragraph
                                className="services__leading"
                                type={ ParagraphType.REGULAR }
                                textType={ TextType.STRING }
                                text={ props.data.tagline } />
                        </motion.div>
                    </div>
                    <ServicesSimpleItems data={ props.data.servicesItems } />
                </div>
                <div className="services__spacer"></div>
                <div className="row">
                    <ServicesSimpleTiles data={ props.data.servicesTiles } />
                </div>
            </div>
        </section>
    );
}
 
export default Services;
import React, { FC } from 'react';

import Layout from '../Layout/Layout';
import VideoShowcase from './VideoShowcase';
import About from './About';
import Services from './Services';
import Pricing from './Pricing';
import Contact from './Contact';

import { 
    HeroDataType, 
    AboutDataType, 
    ServicesDataType, 
    PricingDataType,  
    ContactDataType } from '../../providers/TypeProvider';

interface PageBundlerProps {
    heroData: HeroDataType,
    aboutData: AboutDataType,
    servicesData: ServicesDataType,
    pricingData: PricingDataType,
    contactData: ContactDataType
}
 
const SectionBundler: FC<PageBundlerProps> = (props: PageBundlerProps) => ( 
    <Layout>
        <VideoShowcase data={ props.heroData } />
        <main>
            <About data={ props.aboutData } />
            <Services data={ props.servicesData } />
            <Pricing data={ props.pricingData } />
            <Contact data={ props.contactData } />
        </main>
    </Layout>
);
 
export default SectionBundler;
import React, { FC } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

import ServicesSimpleItems from '../Elements/Services/ServicesSimpleItems';
import ServicesSimpleTiles from '../Elements/Services/ServicesSimpleTiles';
import Header, { HeaderType } from '../Elements/Common/Header';
import Paragraph, { ParagraphType, TextType } from '../Elements/Common/Paragraph';

import { simpleFadeInAndTranslateHorizontal } from '../../providers/AnimationProvider';
import { PricingDataType } from '../../providers/TypeProvider';
import PricingCards from '../Elements/Pricing/PricingCards';

interface PricingProps {
    data: PricingDataType
}
 
const Pricing: FC<PricingProps> = (props: PricingProps) => {
    const textAnimation = simpleFadeInAndTranslateHorizontal(.65, 0, -100)

    return (
        <section className="pricing" id="pricing">
            <div className="container">
                <div className="row">
                    <div className="col-24-xs">
                        <motion.div 
                            className="pricing__content-container" 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            transition={ textAnimation.transition }
                            variants={ { 
                                hidden: textAnimation.initial,
                                visible: textAnimation.animate
                            }}>
                            <Header
                                className="noborder"
                                type={ HeaderType.H2 }
                                text={ props.data.header }
                            />
                            <Paragraph
                                className="services__leading"
                                type={ ParagraphType.REGULAR }
                                textType={ TextType.STRING }
                                text={ props.data.tagline } />
                        </motion.div>
                    </div>
                    <div className="row">
                        <PricingCards 
                            priceCards={ props.data.priceCards }
                            priceCard1List={ props.data.priceCard1List }
                            priceCard2List={ props.data.priceCard2List }
                            priceCard3List={ props.data.priceCard3List }
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default Pricing;
import React, { FC } from 'react';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';

import Header, { HeaderType } from '../Common/Header';

import { ServicesItemDataObject } from '../../../providers/TypeProvider';
import Paragraph, { ParagraphType, TextType } from '../Common/Paragraph';


interface ServicesSimpleItemsProps {
    data: ServicesItemDataObject[]
}
 
const ServicesSimpleItems: FC<ServicesSimpleItemsProps> = (props: ServicesSimpleItemsProps) => (
    <>
        { 
            props.data.map((item: ServicesItemDataObject, index: number) => (
                    <div className="col-24-xs col-6-lg">
                        <div className="services__simple-item" key={ index }>
                            <GatsbyImage
                                className="services__simple-item-img"
                                image={ getImage(item.image) as IGatsbyImageData }
                                alt={ item.alt }
                            />
                            <Header
                                type={ HeaderType.H4 }
                                text={ item.headline }
                            />
                            <Paragraph
                                type={ ParagraphType.REGULAR }
                                textType={ TextType.STRING }
                                text={ item.paragraph }
                            />
                        </div>
                    </div>
                )
            ) 
        }
    </>
);

 
export default ServicesSimpleItems;